<template>
  <div>
    <v-card v-if="tokenCheck == 1" style="width: 100%" min-width="400" max-width="600" class="pa-4">
      <div v-if="view == 1">
        <v-card-title class="title font-weight-regular justify-center">
          <span>Регистрация</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <a-form-model v-model="data" :model="model" :errors="errors" @validate="validate($event)" />
            <v-checkbox v-if="needAgree" v-model="isAgree" class="pt-0 mt-0">
              <template v-slot:label>
                <div class="pl-1">
                  Согласен с
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a v-if="invite" href="#" @click.stop="showOffer = true" v-on="on"> Политикой конфиденциальности и Условиями электронного взаимодействия </a>
                      <a v-else target="_blank" href="https://gk-mechti.ru/oferta_client.html" @click.stop v-on="on">
                        Политикой конфиденциальности и условиями электронного взаимодействия
                      </a>
                    </template>
                    Открыть
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn :disabled="!isAgree" color="primary" @click.prevent.stop="submit()">Далее</v-btn>
        </v-card-actions>
      </div>
      <div v-if="view == 2">
        <v-card-title class="text-h5"> Введите код из СМС </v-card-title>
        <v-card-text>
          Пожалуйста, введите одноразовый код из SMS, отправленный на ваш номер телефона, чтобы подтвердить ваше согласие с условиями.
          <div>
            <v-btn :disabled="sending" color="red darken-1" text @click="onGetCode()"> {{ interval.send > 0 ? interval.send + " " : "" }} Выслать код </v-btn>
          </div>
          <v-otp-input v-model="code" length="4" type="number"></v-otp-input>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="view = 1"> Назад </v-btn>
          <v-spacer> </v-spacer>
          <v-btn :disabled="code?.length !== 4 || confirming" color="green darken-1" text @click="onSendCode()">
            {{ interval.confirm > 0 ? interval.confirm + " " : "" }} Отправить
          </v-btn>
        </v-card-actions>
      </div>
      <div v-if="view == 3">
        <v-card-title class="title font-weight-regular justify-center">
          <span>Регистрация</span>
        </v-card-title>
        <v-card-text>
          <p>Вы успешно зарегистрировались</p>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" to="/">Перейти на главную</v-btn>
        </v-card-actions>
      </div>
    </v-card>
    <v-card v-else style="width: 100%" max-width="500" class="pa-4">
      <v-card-title class="title font-weight-regular justify-center">
        <span>{{ tokenError }}</span>
      </v-card-title>
    </v-card>
    <portal to="v-app">
      <show-info />
      <Offer v-if="showOffer" v-model="showOffer" :content="offer" @agree="isAgree = true" />
    </portal>
  </div>
</template>

<script>
import { validate, validators, fillForm } from "./../../components/mixings";
import Offer from "./offer.vue";

export default {
  components: { Offer },
  mixins: [validate, validators, fillForm],
  data() {
    return {
      showOffer: false,
      view: 1,
      data: {},
      model: [
        { name: "nameF", title: "Фамилия", type: "string", validator: ["req"] },
        { name: "nameI", title: "Имя", type: "string", validator: ["req"] },
        { name: "nameO", title: "Отчество", type: "string", validator: [""] },
        {
          name: "phone",
          title: "Номер телефона",
          type: "numberstr",
          validator: ["req", "tel", "login"],
          mask: "*********",
          configUnique: { where: " and status>0" },
          prefix: "+996",
        },
        { name: "password", title: "Пароль", type: "password", validator: ["req", "pass"] },
        { name: "repassword", title: "Повторите пароль", type: "password", validator: ["req", "repass"] },
      ],
      errors: {},
      code: null,
      isAgree: false,
      needAgree: true,
      sending: false,
      confirming: false,
      interval: { send: 0, confirm: 0 },
      timer: { send: null, confirm: null },
      tokenCheck: 0,
      offer: "",
    };
  },
  computed: {
    fullName: {
      get() {
        let res = "";
        res += this.data.nameF;
        res += this.data.nameI ? " " + this.data.nameI : "";
        res += this.data.nameO ? " " + this.data.nameO : "";
        return res;
      },
    },
    tokenError() {
      let res =
        this.tokenCheck == 2
          ? "Не верная ссылка на регистрацию"
          : this.tokenCheck == 3
          ? "Не удается проверить ссылку для регистрации"
          : this.tokenCheck == 4
          ? "Ошибка при проверки ссылки"
          : "Проверка ссылки на регистрацию";
      return res;
    },
  },
  created() {
    this.checkToken();
  },
  methods: {
    async getOffer(n) {
      if (!n) return;
      let o = await this.$axios.get("/signup/registration/" + n);
      this.offer = o.data?.data;
    },
    async checkToken() {
      this.tokenCheck = 0;
      await this.getOffer(this.$route.meta.type);
      if (this.$route.meta.type == "shop") {
        this.model = [{ name: "shopData.name", title: "Название магазина", type: "string", validator: ["req"] }, ...this.model];
        this.fillFormFromModel(this.model);
        this.tokenCheck = 1;
        this.showOffer = !!this.offer;
        this.needAgree = !!this.offer;
        this.isAgree = !false;
        this.loading = false;
        this.data["role"] = 80;
      }
      if (this.$route.meta.type == "vendor") {
        this.model = [{ name: "vendorData.name", title: "Наименование поставщика", type: "string", validator: ["req"] }, ...this.model];
        this.fillFormFromModel(this.model);
        this.tokenCheck = 1;
        this.showOffer = !!this.offer;
        this.needAgree = !!this.offer;
        this.isAgree = !false;
        this.loading = false;
        this.data["role"] = 100;
      }
    },
    async submit() {
      let silent = true;
      if (await this.validateAll(this.data)) {
        try {
          this.data["name"] = this.fullName;
          this.data["data"] = { f: this.data.nameF, i: this.data.nameI, o: this.data.nameO };
          this.data["login"] = "996" + this.data.phone;
          let response = await this.$axios.post("/signup/registration", this.data);
          console.log(response.data);
          if (response.data?.status == "ok") {
            this.data["id"] = response.data.id;
            if (!silent)
              this.$root.$emit("show-info", {
                text: "Данные записаны",
              });
          }

          this.afterSave();
          this.view = 2;
        } catch (error) {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка проверки правильности ввода " + (error?.data?.message || "null"),
          });
          this.loading = false;
          return;
        }
      }
    },
    afterSave(data, status) {},
    delay(name, n) {
      this.interval[name] = n;
      this.timer[name] = setInterval(() => {
        if (this.interval?.[name]) {
          this.interval[name]--;
        } else clearTimeout(this.timer[name]);
      }, 1000);
    },
    async onGetCode() {
      let id = this.data?.id;
      if (!id) return;
      this.sending = true;
      try {
        const d = await this.$axios.get("/signup/registration/getconfirm/" + id);
        console.log(d.data);
        if (d.data && d.data.status === "ok") {
          this.$root.$emit("show-info", {
            time: 10000,
            text: `СМС с кодом отправлена на номер  ${d.data?.data?.data?.phone}`,
          });
        } else
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка при отправки СМС",
          });
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка " + error?.data?.message,
        });
      }
      this.delay("send", 60);
      setTimeout(() => {
        this.sending = false;
      }, 60000);
    },
    async onSendCode() {
      let id = this.data?.id;
      if (!id) return;
      this.confirming = true;
      try {
        const d = await this.$axios.post("/signup/registration/sendconfirm/" + id, { code: this.code });
        console.log(d.data);
        if (d.data && d.data.status == "ok") {
          this.$root.$emit("show-info", {
            text: "Код принят",
          });
          this.view = 3;
        } else
          this.$root.$emit("show-info", {
            type: "error",
            text: "Неверный код",
          });
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Запросите проверочный код.",
        });
        console.log(error?.message);
      }
      this.code = null;
      this.delay("confirm", 5);

      setTimeout(() => {
        this.confirming = false;
      }, 12000);
    },
  },
};
</script>
