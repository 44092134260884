<template>
  <dialog-edit :value="value" @input="$emit('input')" class="ma-4">
    <template v-slot:title>
      <br />
    </template>
    <div v-if="!loading" class="pa-3" style="text-align: initial" v-html="content"></div>
    <a-loader v-if="loading" />
    <template v-if="!loading" v-slot:actions>
      <v-btn
        @click="
          $emit('agree');
          $emit('input');
        "
      >
        Согласен
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('input')">Закрыть</v-btn>
    </template>
  </dialog-edit>
</template>

<script>
export default {
  props: {
    value: Boolean,
    content: String,
    id: Number,
  },
  data() {
    return {
      api: "/mechti/doc_template",
      data: {},
      loading: false,
    };
  },
  computed: {
    content1() {
      return this.data.content;
    },
  },
  created() {
    this.loading = false;
    if (this.id) this.updateData(this.id);
  },
  watch: {
    value() {
      if (this.value) {
        this.updateData(this.id);
      }
    },
  },
  methods: {
    async updateData() {
      this.loading = false;
      return;
      this.loading = true;
      const r = await this.$axios.get(this.api + "/" + this.id);
      this.data = r.data.data;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
